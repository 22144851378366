<template>
	 


 <el-container>
	<el-header>
	</el-header> 
		
  <el-main>

	<el-row  type="flex" class="row-bg" justify="center">
		<el-col :xl="6" :lg="7">

			<h2 style="font-color:white"> <el-image src="https://vue.vgd100.com/assets/logo3.png" style="height:25px; width:28px;   " ></el-image> 
				欢迎来到服务器建站技术分享</h2>
 

 <el-image src="https://vue.vgd100.com/assets/ad8bebfbfe627.jpg" style="height:300px; width:400px;" ></el-image> 
 <h4>推荐使用谷歌Chrome浏览器以取得最佳页面效果；</h4>
     
	 <h4>若无软件安装权限，请联系本站管理员,15373251677。</h4>


  <h1>{{ ip }}</h1>
 
		</el-col>

		<el-col :span="1">
			<el-divider direction="vertical"></el-divider>
		</el-col>
		<el-col :xl="6" :lg="7">
			<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="80px">
				<el-form-item label="用户名" prop="username" style="width: 380px;line-height: 250px;">
					<el-input v-model="loginForm.username"  prefix-icon="el-icon-user">  
					 
						</el-input>
				</el-form-item>
				
				<el-form-item label="密码" prop="password"  style="width: 380px;line-height: 450px;">
					<el-input v-model="loginForm.password" type="password"  prefix-icon="el-icon-lock">
						 
						 </el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code"  style="width: 380px;line-height: 250px;">
					<el-input v-model="loginForm.code"  style="width: 172px; float: left" maxlength="5"></el-input>
					<el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('loginForm')">立即登录</el-button>
					<el-button @click="resetForm('loginForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-col>

		

	</el-row>

	
 

  </el-main>
  <el-footer>
	<el-row  >
  <el-col  ><div class="grid-content bg-purple">Copyright 2004 vgd100.com . All Rights Reserved</div> 
	<div class="grid-content bg-purple">中华人民共和国信息产业部备案 
	<el-image    style="width: 22px; height: 25px"      :src=  "url"       ></el-image>
   ( <a href="https://beian.miit.gov.cn" style="color: #000;" target="_blank"><span >冀ICP备16000909号-4</span></a>  ) </div></el-col>
</el-row>
</el-footer>
</el-container>



	


	 
 


</template>


  
  <script>


  
 
	import qs from 'qs'
 
	export default {
	 
		name: "Login" ,
		data() {
			return {
				ipUrl:"",
				  ip:'',
				 
        url: 'https://vue.vgd100.com/assets/icp.gif',
				loginForm: {
					username: 'admin',
					password: '',
					code: '',
					token: ''
				},
				rules: {
					username: [
						{ required: true, message: '请输入用户名', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					],
					code: [
						{ required: true, message: '请输入验证码', trigger: 'blur' },
						{ min: 5, max: 5, message: '长度为 5 个字符', trigger: 'blur' }
					],
				},
				captchaImg: null
			};
		},
		methods: {
			 
                
               
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/login?'+ qs.stringify(this.loginForm)).then(res => {

							console.log(res)

							const jwt = res.headers['authorization']

							this.$store.commit('SET_TOKEN', jwt)
							this.$router.push("/index")
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			getCaptcha() {
				this.$axios.get('/captcha').then(res => {

					console.log("/captcha")
					console.log(res)

					this.loginForm.token = res.data.data.token
					this.captchaImg = res.data.data.captchaImg
					this.loginForm.code = ''
				})
			}
			 

		 } ,
		created() {      
 this.getCaptcha()
		} 
	}
</script>

<style scoped>

.el-container
{  
	margin: 0px;
	height: 100%;
		background-image:url(https://vue.vgd100.com/assets/keji-8-4-9.png) ;
	    background-position: center;
		background-size: 100% auto;
		background-repeat: no-repeat;
		width: 100%;
		border-top-style:solid;
		border-top-color:  #0691d7;
		border-block-width: 1px;
		display: block;
}

	.row-bg 
	{
	width: 100%;
	background-position: center;
	}
 /* *{
	margin: 0px;
	padding: 0px;
 } */
 body,html{
	margin: 0px;
	padding: 0px;
	background-color: #0691d7;
	font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
 }
	.el-row   {
		background-color: #0691d7;
		/* background:url( http://10.83.16.181/mac/media/image/b_5c9b3e9c98ffd.png)  ; */
		  height: 100%;  
		 display: flex; 
		align-items: center;
		text-align: center;
		justify-content: center;
		 
	}

	.el-header 
	{
    background-color: #0691d7;
    width: 100%;
	height: 25%;
	/* display: flex; */
	align-items: center;
	text-align: center;
	justify-content: center;
    }



	.el-main {
	background-color: #0691d7;
    color: #333;
    text-align: center;
    line-height:  30px;
	height: 89%;
	justify-content: center;
  }
 .el-footer {
	font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	background-color: #0691d7;
	width: 100%;
		height: 5%;
		/* display: flex; */
		align-items: center;
		text-align: center;
		justify-content: center;

 }
  
	.el-divider {
		height: 300px;
	}

	.captchaImg {
		float: left;
		margin-left: 8px;
		border-radius: 4px;
	}

</style>